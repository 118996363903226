import { Box, Typography } from '@kitalulus/web-ui-kit';

const TermCondition = () => {
  return (
    <>
      <Typography variant="body1" sx={{ py: '8px' }} textAlign="justify">
        Pada dokumen Syarat dan Ketentuan Penggunaan (“S&K”) ini menyatakan
        syarat dan ketentuan yang perlu Pengguna penuhi dan setujui untuk dapat
        menggunakan aplikasi dan/atau situs yang Kami sediakan (“Layanan”)
        dengan segala fitur dan kegunaannya, dan hubungan Pengguna dengan PT
        Kita Lulus Internasional (“KitaLulus” atau “Kami”) sebagai penyedia
        Layanan. Harap baca S&K dengan seksama karena isinya akan mempengaruhi
        hak dan kewajiban Pengguna berdasarkan hukum. Jika Pengguna tidak
        menyetujui S&K ini, mohon untuk tidak menggunakan dan/atau mendaftar
        Layanan.
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Penggunaan Layanan melibatkan beberapa pihak yang masing-masing memiliki
        hak dan kewajiban yang perlu dipenuhi. Pihak-pihak tersebut adalah:
      </Typography>

      <ol style={{ margin: 0, padding: '0 24px' }}>
        <li>KitaLulus, sebagai penyedia Layanan.</li>
        <li>
          Pengguna Layanan (“Pengguna” atau “Anda”), yaitu individu yang
          bertujuan untuk mencari informasi lowongan pekerjaan dan/atau melamar
          pekerjaan (“Pencari Kerja”) dan individu atau entitas bisnis melalui
          perwakilannya yang sedang mencari pekerja atau karyawan pada Layanan
          (“Perusahaan”) serta individu yang mengakses Layanan untuk tujuan
          lainnya.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        Ketentuan Umum Pengguna
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Dengan menggunakan Layanan, Anda sebagai Pengguna menyatakan bahwa:
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Pengguna setuju untuk terikat dengan S&K yang ditetapkan serta
          kebijakan mengenai pembaruan S&K di kemudian hari. Pengguna
          dipersilahkan untuk tidak menggunakan dan/atau mengakses Layanan jika
          tidak setuju untuk terikat dengan S&K ini atau pun pembaruannya.
        </li>
        <li>
          Apabila Pengguna belum dewasa menurut ketentuan perundang-undangan
          yang berlaku, wajib mendapatkan bimbingan dan pengawasan orang tua
          dalam menggunakan Layanan.
        </li>
        <li>
          Jika ada pertentangan antara S&K dengan kontrak apa pun yang Pengguna
          miliki dengan KitaLulus, Ketentuan ini yang akan diutamakan (kecuali
          dinyatakan lain).
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        1. Registrasi
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Untuk mendaftarkan akun pada Layanan, Pengguna harus berusia 18 tahun
          ke atas.
        </li>
        <li>
          Data-data yang Pengguna masukkan untuk proses registrasi harus
          merupakan data tepat dan lengkap.
        </li>
        <li>
          Pengguna diperbolehkan mendaftar atas nama pribadi atau perusahaan
          tempat Pengguna bekerja (apabila Pengguna memiliki hak), dan tidak
          diperbolehkan mendaftar atas nama orang atau perusahaan lain.
        </li>
        <li>
          Pengguna bertanggung jawab untuk menjaga kerahasiaan data yang
          Pengguna gunakan untuk masuk ke akun Pengguna.
        </li>
        <li>
          Dalam hal Pengguna lupa kata sandi akun, Pengguna dapat mengikuti
          langkah-langkah yang terdapat pada Layanan untuk membuat kata sandi
          baru. Apabila terdapat langkah tertentu yang tidak dapat Pengguna
          penuhi, maka Pengguna tidak akan mendapatkan akses untuk membuat kata
          sandi baru.
        </li>
        <li>
          Jika Kami memiliki alasan untuk meyakini bahwa akan atau telah terjadi
          pelanggaran keamanan atau penyalahgunaan akun Pengguna oleh pihak yang
          tidak berhak, Kami akan meminta Pengguna untuk mengubah kata sandi
          Pengguna atau Kami dapat menangguhkan akun Pengguna demi keamanan.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        2. Kekayaan Intelektual
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Aplikasi, situs, akun media sosial, dan semua layanan yang dimiliki
          KitaLulus adalah milik eksklusif KitaLulus atau pemberi lisensinya.
        </li>
        <li>
          KitaLulus dan Layanan dilindungi oleh hukum hak cipta, hukum merek,
          dan hukum hak kekayaan intelektual lainnya.
        </li>
        <li>
          Pengguna dapat menggunakan dan menikmati layanan dan tampilan Layanan.
          Pengguna tidak boleh mereproduksi, memodifikasi, menyalin atau
          mendistribusikan atau menggunakan untuk tujuan komersial hal apa pun
          di Layanan, tanpa izin tertulis dari KitaLulus.
        </li>
        <li>
          Setiap Pengguna Layanan setuju untuk tidak menggunakan Layanan dengan
          cara apa pun yang melanggar hak kekayaan intelektual atau hak milik
          orang lain seperti:
          <Box sx={{ ol: { m: 0, p: '0 24px' } }}>
            <ol type="i">
              <li>
                mencetak, mengunduh, menggandakan, mengirimkan atau menyalin,
                mereproduksi, mendistribusikan ulang, menerbitkan ulang, atau
                menggunakan informasi pribadi apapun tentang pengguna lain,
                kecuali telah mendapatkan izin tertulis atau dinyatakan lain
                pada S&K;
              </li>
              <li>
                merekayasa balik (reverse engineer) atau mendekompilasi bagian
                mana pun dari Layanan;
              </li>
              <li>
                membuat akun dengan identitas orang lain, menggunakan nama
                perusahaan secara tanpa hak, atau mengunggah atau mengubah
                lowongan pekerjaan secara tanpa hak;
              </li>
              <li>
                mengakses data yang tidak ditujukan untuk Pengguna atau masuk ke
                server atau akun yang tidak diizinkan untuk Pengguna akses; dan
              </li>
              <li>
                hal-hal lain yang dilakukan secara tanpa hak dan dapat
                menimbulkan kerugian secara materiil atau pun immateriil bagi
                pihak lain.
              </li>
            </ol>
          </Box>
        </li>
        <li>
          Kami tidak mengklaim kepemilikan konten Pengguna yang diposting pada
          Layanan dan Anda bebas untuk berbagi konten Anda dengan orang lain
          pada media yang telah Kami sediakan di Layanan. Namun, Kami memerlukan
          izin hukum tertentu dari Pengguna (dikenal sebagai "lisensi") untuk
          menyediakan Layanan. Saat Pengguna membagikan, memposting, atau
          mengunggah konten yang dilindungi oleh hak kekayaan intelektual
          (seperti foto atau video) pada atau sehubungan dengan Layanan Kami,
          Pengguna dengan ini memberikan kepada Kami konten non-eksklusif, bebas
          royalti, dapat dialihkan, disublisensikan, lisensi di seluruh dunia
          untuk menggunakan, mendistribusikan, memodifikasi, menjalankan,
          menyalin, dan menampilkan secara publik. Lisensi ini akan berakhir
          ketika konten Pengguna tersebut dihapus dari sistem Kami.
        </li>
        <li>
          Segala konten yang terdapat pada Layanan tersimpan dalam bentuk
          elektronik atau dicetakkan dalam satu salinan untuk penggunaan pribadi
          dan non-komersial bagi Pengguna, asalkan Pengguna menjaga semua hak
          kekayaan intelektual yang terdapat didalamnya. Pengguna tidak boleh
          mereproduksi, memodifikasi, menyalin atau mendistribusikan atau
          menggunakan untuk tujuan komersial atau terhadap setiap konten pada
          Layanan tanpa izin tertulis dari Kami.
        </li>
        <li>
          Dengan menggunakan Layanan, Pengguna (khususnya Perusahaan) bersedia
          memberikan lisensi atas merek dan logo miliknya kepada KitaLulus
          sepanjang digunakan untuk tujuan-tujuan promosional dengan cara yang
          tidak melanggar hukum yang berlaku di Indonesia.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        3. Penggunaan Layanan
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          KitaLulus dengan ini memberi Anda hak non-eksklusif yang terbatas,
          dapat dihentikan, untuk mengakses dan menggunakan Layanan hanya untuk
          penggunaan pribadi dan/atau tujuan pekerjaan Anda.
        </li>
        <li>
          Layanan dapat diakses bagi:
          <Box sx={{ ol: { m: 0, p: '0 24px' } }}>
            <ol type="i">
              <li>
                individu yang ingin mencari informasi mengenai lowongan
                pekerjaan dan/atau melamar pekerjaan;
              </li>
              <li>
                Perusahaan atau pencari kandidat pekerja untuk lowongan kerja
                yang valid;
              </li>
              <li>
                individu yang tergabung atau pun memiliki minat untuk bergabung
                di komunitas yang tersedia pada Layanan;
              </li>
              <li>
                individu yang ingin mengakses soal-soal CPNS dan/atau PPPK yang
                tersedia pada Layanan;
              </li>
              <li>
                Setiap orang yang ingin mengetahui fitur yang terdapat pada
                Layanan tanpa adanya iktikad buruk untuk melanggar Hak Kekayaan
                Intelektual yang dimiliki KitaLulus.
              </li>
            </ol>
          </Box>
        </li>
        <li>
          Penggunaan Pengguna atas Layanan juga tunduk pada kontrak lain yang
          mungkin Pengguna miliki dengan KitaLulus. Jika ada pertentangan antara
          S&K dan kontrak apa pun yang Pengguna miliki dengan KitaLulus,
          Ketentuan ini yang akan berlaku (kecuali dinyatakan lain).
        </li>
        <li>
          Dengan menggunakan Layanan, Pengguna menyatakan bahwa:
          <ol type="i" style={{ margin: 0, padding: '0 24px' }}>
            <li>
              tidak akan melanggar hak kekayaan intelektual milik orang lain
              yang berhubungan dengan Layanan;
            </li>
            <li>
              tidak akan membagikan informasi kredensial untuk masuk ke akun
              Layanan Pengguna pada pihak ketiga mana pun;
            </li>
            <li>
              Tidak akan melanggar atau berusaha melanggar sistem keamanan
              Layanan;
            </li>
            <li>tidak menggunakan atau membuat akun secara tanpa hak;</li>
            <li>
              setiap informasi telah dipastikan kebenarannya, sehingga tidak
              akan ada disinformasi, misinformasi, atau pun berita bohong yang
              disebarkan melalui Layanan oleh Pengguna;
            </li>
            <li>
              tidak akan melakukan tindakan penipuan, pengecohan, diskriminasi,
              atau pun penggunaan Layanan dengan cara-cara lain yang tidak
              diperbolehkan pada S&K dan/atau hukum yang berlaku;
            </li>
            <li>
              tidak akan melakukan tindakan-tindakan yang mencerminkan ujaran
              kebencian dan/atau mempertentangkan SARA (Suku, Ras, Agama, dan
              Antar Golongan);
            </li>
            <li>bertanggung jawab atas setiap hal yang diunggah; dan</li>
            <li>
              setuju dengan segala isi S&K dan Kebijakan Privasi KitaLulus.
            </li>
          </ol>
        </li>
        <li>
          Pengguna tidak boleh menggunakan Layanan untuk tujuan-tujuan sebagai
          berikut:
          <ol type="i" style={{ margin: 0, padding: '0 24px' }}>
            <li>
              menyebarkan materi yang melecehkan, memfitnah, kasar, mengancam,
              berbahaya, vulgar, cabul, atau tidak pantas atau materi yang apa
              pun yang melanggar hukum;
            </li>
            <li>
              menggabungkan, menyalin, atau menggandakan dengan cara apa pun
              konten yang terdapat pada Layanan atau informasi yang tersedia
              pada Layanan, termasuk lowongan pekerjaan yang sudah kedaluwarsa;
            </li>
            <li>mereproduksi konten KitaLulus untuk penggunaan umum;</li>
            <li>
              menautkan ke konten pada Layanan atau informasi apa pun yang
              tersedia pada Layanan;
            </li>
            <li>
              mentransmisikan materi yang mendorong perilaku yang merupakan
              tindak pidana, atau melanggar hukum, atau peraturan yang berlaku;
            </li>
            <li>
              mengganggu penggunaan atau kenikmatan orang lain terhadap Layanan;
            </li>
            <li>
              membuat, mengirimkan atau menyimpan salinan elektronik dari materi
              yang dilindungi oleh hak cipta tanpa izin dari pemiliknya; atau
            </li>
            <li>
              tidak menjual kembali atau mengalihkan hak atau kewajiban Anda
              berdasarkan S&K ini. Anda juga dilarang untuk tidak melakukan
              penggunaan komersial yang tidak sah atas Layanan.
            </li>
          </ol>
        </li>
        <li>
          Dengan menggunakan Layanan, Anda setuju untuk tidak:
          <ol type="i" style={{ margin: 0, padding: '0 24px' }}>
            <li>
              mengirimkan, memasang, mendistribusikan, menyimpan atau
              menghancurkan materi, termasuk namun tidak terbatas pada konten
              KitaLulus pada Layanan, yang melanggar hukum atau peraturan yang
              berlaku, termasuk namun tidak terbatas pada hukum atau peraturan
              yang mengatur pengumpulan, pemrosesan, atau pemindahan informasi
              pribadi, atau pelanggaran Kebijakan Privasi Layanan;
            </li>
            <li>melanggar atau berusaha melanggar keamanan Layanan;</li>
            <li>
              merekayasa balik atau mendekompilasi bagian mana pun dari Layanan;
            </li>
            <li>
              menggabungkan, menyalin, atau menggandakan dalam hal apa pun
              konten KitaLulus atau informasi yang tersedia dari Layanan,
              termasuk lowongan pekerjaan yang sudah kedaluwarsa, selain yang
              diizinkan oleh S&K;
            </li>
            <li>
              memposting konten atau materi apa pun yang memfasilitasi,
              mempromosikan atau mendukung penipuan, informasi palsu atau
              menyesatkan atau aktivitas ilegal, ATAU mendukung atau memberikan
              informasi instruksional tentang aktivitas ilegal atau aktivitas
              lain yang dilarang oleh Ketentuan ini, seperti membuat atau
              membeli senjata ilegal, melanggar privasi seseorang, menyediakan
              atau membuat virus komputer atau media pembajakan, ATAU
              mempromosikan atau mendukung pandangan politik apa pun;
            </li>
            <li>
              memposting profil apa pun, melanjutkan, atau melamar pekerjaan apa
              pun atas nama pihak lain;
            </li>
            <li>
              berbagi dengan pihak ketiga setiap kredensial login ke Layanan;
            </li>
            <li>
              mengakses data yang tidak ditujukan untuk Anda atau masuk ke
              server atau akun yang tidak diizinkan untuk Anda akses;
            </li>
            <li>
              memposting atau mengirimkan ke Layanan setiap informasi biografi
              yang tidak akurat, tidak lengkap, menyesatkan, palsu, tidak
              terkini atau informasi yang bukan milik Anda;
            </li>
            <li>
              memposting konten yang berisi halaman akses terbatas atau kata
              sandi saja, atau halaman atau gambar tersembunyi;
            </li>
            <li>
              meminta kata sandi atau informasi pengenal pribadi dari Pengguna
              lain;
            </li>
            <li>
              menghapus atau mengubah materi apa pun yang diposting oleh orang
              atau entitas lain mana pun;
            </li>
            <li>
              melecehkan, menghasut pelecehan, atau menganjurkan pelecehan
              terhadap kelompok, perusahaan, atau individu mana pun;
            </li>
            <li>
              mengirim surat atau email yang tidak diminta, melakukan panggilan
              telepon yang tidak diminta atau mengirim faks yang tidak diminta
              yang mempromosikan dan/atau mengiklankan produk atau layanan
              kepada Pengguna mana pun, atau menghubungi pengguna yang secara
              khusus meminta untuk tidak dihubungi oleh Anda;
            </li>
            <li>
              berupaya mengganggu Layanan terhadap Pengguna, host, atau jaringan
              mana pun, termasuk, tanpa batasan, melalui cara mengirimkan virus,
              “spam”, “mailbombing”, atau “crashing”, ke Layanan.
            </li>
            <li>
              menggunakan Layanan untuk tujuan yang melanggar hukum atau
              aktivitas ilegal, atau memposting atau mengirimkan konten, profil,
              resume, atau postingan pekerjaan yang memfitnah, memfitnah, secara
              implisit atau eksplisit menyinggung, vulgar, cabul, mengancam,
              melecehkan, kasar, penuh kebencian, rasis, diskriminatif, bersifat
              mengancam atau mungkin menyebabkan gangguan, ketidaknyamanan, rasa
              malu, kecemasan atau dapat menyebabkan pelecehan kepada siapa pun
              atau menyertakan tautan apa pun ke materi pornografi, tidak
              senonoh, atau eksplisit secara seksual dalam bentuk apa pun; atau
            </li>
            <li>
              memposting profil atau resume apa pun yang bukan profil atau
              resume asli.
            </li>
          </ol>
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        4. Ketersediaan Layanan Kitalulus
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Kami memiliki tujuan untuk menawarkan layanan terbaik kepada Pengguna,
          namun Kami tidak berjanji bahwa Layanan Kami akan memenuhi kebutuhan
          semua Pengguna.
        </li>
        <li>
          Kami tidak dapat menjamin bahwa Layanan akan bebas dari kesalahan,
          bebas dari error, atau bahwa Layanan bebas dari virus atau mekanisme
          berbahaya lainnya. Jika terjadi kesalahan pada Layanan, Pengguna harus
          melaporkannya melalui customer service KitaLulus melalui WhatsApp
          dengan nomor 085880006500 dan Kami akan berusaha memperbaiki kesalahan
          tersebut sesegera mungkin.
        </li>
        <li>
          Akses Pengguna ke Layanan mungkin akan dibatasi pada waktu tertentu
          saat adanya perbaikan, pemeliharaan, atau pengenalan konten,
          fasilitas, atau layanan baru. Kami akan berusaha memulihkan akses pada
          Layanan sesegera mungkin.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        5. Perusahaan
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Perusahaan harus mengisi data-data profil Perusahaan yang diminta pada
          Layanan secara lengkap dan tepat.
        </li>
        <li>
          Perusahaan bertanggung jawab atas setiap lowongan kerja yang mereka
          unggah.
        </li>
        <li>
          KitaLulus tidak akan mengubah unggahan lowongan kerja Perusahaan
          secara sepihak, kecuali telah terdapat kesepakatan lain sebelumnya.
        </li>
        <li>
          Perusahaan dilarang untuk memungut biaya selama proses rekrutmen
          melalui Layanan.
        </li>
        <li>
          Perusahaan wajib menjunjung tinggi kesopanan dalam berkomunikasi
          dengan setiap pihak pada Layanan.
        </li>
        <li>
          Lowongan kerja yang diunggah Perusahaan tidak boleh mengandung unsur
          diskriminatif, atau penipuan, atau pengecohan yang merugikan Pencari
          Kerja dan/atau KitaLulus.
        </li>
        <li>
          Setiap konten lowongan pekerjaan yang diunggah Perusahaan tidak boleh
          mengandung:
          <ol type="i" style={{ margin: 0, padding: '0 24px' }}>
            <li>
              kata kunci yang menyesatkan, tidak dapat dibaca, atau
              “tersembunyi”, kata kunci berulang atau kata kunci yang tidak
              relevan dengan lowongan kerja yang ditampilkan;
            </li>
            <li>
              nama, logo atau merek dagang dari perusahaan yang tidak
              terafiliasi;
            </li>
            <li>informasi yang tidak akurat, palsu, atau menyesatkan; dan</li>
            <li>
              materi atau tautan ke materi yang diskriminatif, eksplisit secara
              seksual, cabul, memfitnah, memfitnah, mengancam, melecehkan,
              kasar, atau penuh kebencian.
            </li>
            <li>
              informasi yang menyerupai atau sama dengan informasi lowongan
              kerja sebelumnya sudah diunggah dan masih bersifat aktif.
            </li>
          </ol>
        </li>
        <li>
          Perusahaan tidak diperbolehkan untuk:
          <ol type="i" style={{ margin: 0, padding: '0 24px' }}>
            <li>
              memposting pekerjaan dengan cara yang tidak mematuhi undang-undang
              yang berlaku, termasuk namun tidak terbatas pada undang-undang
              yang berkaitan dengan tenaga kerja dan ketenagakerjaan, kesempatan
              kerja yang sama dan persyaratan kelayakan pekerjaan, privasi data,
              akses dan penggunaan data, dan kekayaan intelektual;
            </li>
            <li>
              memposting lowongan pekerjaan untuk ditempatkan secara fisik di
              luar negeri;
            </li>
            <li>
              tanpa seizin KitaLulus, bekerja sama dengan Perusahaan Penempatan
              Pekerja Migran Indonesia (P3MI) dan memposting lowongan pekerjaan
              untuk membantu P3MI dalam mendapatkan calon Pekerja Migran
              Indonesia.
            </li>
            <li>
              menjual, mempromosikan atau mengiklankan produk atau layanan milik
              Perusahaan, kecuali terdapat kontrak lain yang menyatakan
              sebaliknya;
            </li>
            <li>
              memposting setiap peluang waralaba, skema piramida, distribusi,
              atau pemasaran multi-level;
            </li>
            <li>
              mempromosikan setiap kesempatan yang tidak mewakili pekerjaan yang
              bonafide;
            </li>
            <li>
              mengiklankan layanan seksual atau mencari karyawan untuk pekerjaan
              yang bersifat seksual;
            </li>
            <li>
              memposting dan/atau mengelola lowongan pekerjaan tanpa adanya
              kebutuhan untuk mempekerjakan karyawan pada posisi tersebut dalam
              waktu 30 hari kalender sejak lowongan kerja diposting; dan
            </li>
            <li>
              mengontak dan/atau melakukan shortlist terhadap lebih dari 300
              (tiga ratus) Pencari Kerja untuk setiap iklan lowongan pekerjaan.
            </li>
          </ol>
        </li>
        <li>
          Apabila terdapat suatu lowongan kerja yang terindikasi melanggar S&K,
          KitaLulus berhak untuk menghapus lowongan kerja tersebut.
        </li>
        <li>
          KitaLulus dapat menghapus atau menonaktifkan akun Perusahaan jika
          dianggap telah melanggar S&K yang berlaku.
        </li>
        <li>
          KitaLulus tidak memiliki tanggung jawab hukum atas ketidaktepatan
          informasi lowongan kerja pada Layanan.
        </li>
        <li>
          Anda memahami dan mengakui bahwa jika Anda menghapus akun Perusahaan
          Anda atau akun Perusahaan Anda dihentikan, semua informasi akun Anda
          yang terdapat dalam Layanan, termasuk Profil dan resume yang
          tersimpan, kontak jaringan, dan lain-lain, akan dihapus dari basis
          data Layanan, kecuali ditentukan lain berdasarkan S&K.
        </li>
        <li>
          KitaLulus dapat sewaktu-waktu mengadakan fitur di mana Perusahaan
          dapat meminta dokumen tertentu milik Pencari Kerja yang melamar pada
          Perusahaan. Dalam hal fitur tersebut telah tersedia dan Perusahaan
          memutuskan untuk menggunakannya, Perusahaan menyatakan dan menjamin
          bahwa akan menjaga setiap dokumen milik Pencari Kerja sebagaimana
          Perusahaan menjaga informasi rahasianya sendiri, serta wajib melakukan
          penghapusan dokumen milik Pencari Kerja atas permintaan dari Pencari
          Kerja atau KitaLulus.
        </li>
        <li>
          KitaLulus dapat meminta Perusahaan untuk menyerahkan beberapa dokumen
          dari Perusahaan dengan tujuan memverifikasi validitas Perusahaan.
          Dalam hal ini, KitaLulus akan menyimpan dokumen Perusahaan dan tidak
          akan menyebarkan dokumen tersebut kepada pihak lain mana pun kecuali
          terdapat ketentuan lain antara KitaLulus dengan Perusahaan terkait
          atau diwajibkan oleh pihak yang berwenang secara hukum.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        6. Pencari Kerja
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          KitaLulus dengan ini memberi Pengguna hak non-eksklusif yang terbatas,
          dapat dihentikan, untuk mengakses dan menggunakan Layanan hanya untuk
          penggunaan pribadi Pengguna mencari peluang kerja untuk diri Pengguna
          sendiri dan bukan untuk orang lain. Saat Pengguna mendaftar di
          Layanan, Pengguna akan diminta untuk membuat akun dan memberikan
          informasi tertentu kepada KitaLulus.
        </li>
        <li>
          Setiap data pada profil yang Pengguna isi harus akurat, lengkap,
          terkini dan tidak menyesatkan. Pengguna tidak boleh mengisi data pada
          profil dengan data orang lain.
        </li>
        <li>
          Pengguna setuju bahwa Pengguna bertanggung jawab penuh atas bentuk,
          isi, dan keakuratan setiap data yang Pengguna berikan pada profil akun
          Pengguna.
        </li>
        <li>
          KitaLulus berhak untuk menawarkan layanan dan produk pihak ketiga
          kepada Pengguna dan setelah penawaran tersebut dapat dibuat oleh
          Layanan atau oleh pihak ketiga.
        </li>
        <li>
          Pengguna memahami bahwa semua informasi yang Pengguna berikan, data
          profil Pengguna, resume, dan/atau informasi profil harus dan akan
          diungkapkan kepada Perusahaan.
        </li>
        <li>
          Pengguna diwajibkan menjunjung tinggi kesopanan dalam berkomunikasi
          dengan setiap pihak pada Layanan.
        </li>
        <li>
          KitaLulus berhak untuk menghapus atau menonaktifkan akun Pengguna dan
          semua data Pengguna setelah lama tidak aktif atau dianggap melanggar
          S&K yang berlaku.
        </li>
        <li>
          Pengguna dapat melaporkan lowongan kerja yang terindikasi penipuan
          dengan menghubungi customer service KitaLulus melalui WhatsApp dengan
          nomor 085880006500.
        </li>
        <li>
          Pencari Kerja setuju bahwa untuk mendukung keamanan Layanan bagi semua
          Pengguna, KitaLulus atas permintaan tertentu dapat melakukan
          pemeriksaan latar belakang Pencari Kerja yang antara lain meliputi
          verifikasi nama dan Nomor Induk Kependudukan, alamat, tanggal lahir,
          pengecekan catatan kriminal dan/atau kredit.
        </li>
        <li>
          Anda memahami dan mengakui bahwa Anda tidak memiliki hak kepemilikan
          atas akun Anda dan bahwa jika Anda menghapus akun Anda atau Akun Anda
          dihentikan, semua informasi akun Anda dalam Layanan, termasuk profil,
          resume, cover surat, pekerjaan yang disimpan, dapat dihapus dari basis
          data Layanan dan dapat dihapus dari area publik mana pun di Layanan.
          Pihak ketiga dapat menyimpan salinan informasi Anda yang tersimpan.
        </li>
        <li>
          KitaLulus berhak untuk menghapus akun Anda dan semua informasi Anda
          setelah lama tidak aktif.
        </li>
        <li>
          KitaLulus sewaktu-waktu dapat mengadakan fitur yang memungkinkan
          Pencari Kerja untuk mengunggah dokumen pribadinya untuk tujuan asesmen
          oleh Perusahaan yang dilamar oleh Pencari Kerja. Dalam hal Pencari
          Kerja menggunakan fitur ini, KitaLulus akan membagikan dokumen milik
          Pencari Kerja yang dibutuhkan oleh Perusahaan untuk menilai apakah
          Pencari Kerja memenuhi persyaratan kerja yang dimiliki Perusahaan
          untuk lowongan kerja yang dilamar oleh Pencari Kerja.
        </li>
        <li>
          Pencari Kerja dapat menautkan akun media sosial miliknya pada akun
          Layanan. Dalam hal Pencari Kerja menautkan media sosialnya, KitaLulus
          berhak untuk menyimpan dan memperlihatkan data dan aktivitas media
          sosial Anda kepada pengguna lain melalui Layanan.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        7. Komunitas
      </Typography>

      <Typography sx={{ py: '8px' }} variant="body1" textAlign="justify">
        Pengguna berhak untuk mengakses, mengikuti, dan membuat postingan pada
        fitur Komunitas yang terdapat pada Layanan setelah Pengguna menyetujui
        S&K dan Kebijakan Privasi Layanan. Ketentuan yang harus dipatuhi selama
        Pengguna menggunakan fitur Komunitas adalah sebagai berikut:
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Pengguna dilarang membuat postingan yang melanggar hak kekayaan
          intelektual orang lain.
        </li>
        <li>
          Pengguna diharuskan menjunjung tinggi kesopanan dalam berkomunikasi
          dalam Komunitas.
        </li>
        <li>
          Dengan melakukan posting pada Komunitas, Pengguna sadar dan setuju
          bahwa <i>username</i> dan foto profil Pengguna akan dapat dilihat oleh
          Pengguna yang melihat postingan tersebut.
        </li>
        <li>
          Dalam menggunakan fitur Komunitas tidak diperbolehkan berdiskusi,
          bertanya, dan/atau membuat <i>polling</i> yang mengandung unsur SARA,
          ujaran kebencian, provokatif, dan unsur lainnya yang dapat mengganggu
          ketertiban atau kenyamanan pengguna lain dan/atau melanggar hukum yang
          berlaku.
        </li>
        <li>
          Pengguna dilarang melakukan disinformasi atau pun menyajikan informasi
          yang masih belum pasti keaslian atau kebenarannya dan berpotensi
          menyebabkan kerugian materiil atau pun immateriil kepada pihak mana
          pun.
        </li>
        <li>
          Pengguna dapat melaporkan postingan yang dianggap tidak layak untuk
          diunggah.
        </li>
        <li>
          Pengguna dapat memblokir Pengguna lain yang bersikap kasar, menghina,
          atau tindakan tidak menyenangkan lainnya pada postingan atau/dan
          komentar pengguna tersebut.
        </li>
        <li>
          KitaLulus berhak menghapus postingan tanpa seizin pembuatnya apabila
          dianggap tidak sesuai dengan ketentuan Komunitas.
        </li>
        <li>
          Pengguna yang melanggar ketentuan Komunitas ini akan mendapatkan
          teguran atau hukuman dari KitaLulus berupa adanya batasan untuk
          mengakses fitur Komunitas atau batasan untuk mengakses Layanan selama
          jangka waktu tertentu atau pun permanen.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        8. KitaLulus Plus
      </Typography>

      <Typography sx={{ py: '8px' }} variant="body1" textAlign="justify">
        KitaLulus Plus adalah layanan yang berfokus pada peningkatan pengetahuan
        dan keterampilan bagi Pencari Kerja. Fitur utama yang akan didapatkan
        oleh Pencari Kerja apabila berlangganan KitaLulus Plus adalah fitur
        BelajarPro, dengan ketentuan sebagai berikut:
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Segala jenis soal dan latihan, baik yang berbayar atau pun tidak, yang
          terdapat pada Layanan hanya untuk digunakan dan dimanfaatkan di dalam
          Layanan.
        </li>
        <li>
          Pengguna tidak diperbolehkan menyimpan dan/atau menyebarkan dalam
          bentuk apa pun soal atau latihan yang terdapat pada Layanan.
        </li>
        <li>
          Pembelian serta pembatalan pembelian Paket Belajar dapat mengikuti
          langkah-langkah yang diberikan pada Layanan.
        </li>
        <li>
          Paket Belajar yang telah Pengguna beli tidak dapat ditransfer ke akun
          lain. Fitur utama Pencari Kerja dapat membeli KitaLulus Plus untuk
          mendapatkan fitur premium dalam Layanan, yaitu sebagai berikut:
        </li>
      </ol>

      <Typography sx={{ py: '8px' }} variant="body1" textAlign="justify">
        Jika Pencari Kerja berlangganan KitaLulus Plus maka Pencari Kerja juga
        akan mendapatkan akses terhadap fitur lainnya berupa fitur pembuatan CV
        serta fitur yang dapat memberikan informasi-informasi tertentu yang
        dapat mengefektifkan pencarian kerja oleh Pencari Kerja.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        9. Misi Seru
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          KitaLulus menyediakan layanan Misi Seru, yang berisi tugas dan/atau
          perintah tertentu yang dapat diakses dan dikerjakan oleh Pengguna
          ("Misi"). Pengguna yang mengakses dan mengerjakan Misi selanjutnya
          disebut sebagai “Pejuang Misi”. Pejuang Misi berhak atas hadiah atas
          setiap Misi yang telah diselesaikannya apabila sesuai dengan segala
          ketentuan yang tercantum dalam S&K ini dan ketentuan lain yang
          KitaLulus tetapkan untuk Misi tersebut dalam bentuk lain (
          <i>“Rewards”</i>).
        </li>
        <li>
          <i>Rewards</i> hanya akan dibayarkan kepada Pejuang Misi yang berhasil
          mengerjakan Misi sesuai dengan panduan Misi yang diberikan oleh
          KitaLulus.
        </li>
        <li>
          Pejuang Misi hanya diperbolehkan untuk menyelesaikan Misi dengan
          menggunakan akun media sosial yang benar-benar digunakan secara aktif
          dan dimiliki oleh Pejuang Misi serta dapat diakses oleh umum (tidak
          berada dalam mode <i>private</i>).
        </li>
        <li>
          Nomor handphone dan/atau email yang digunakan pada Misi sebaiknya
          merupakan nomor dan/atau email yang sudah terdaftar di KitaLulus, agar
          memudahkan pemberitahuan status Misi yang sudah Pejuang Misi kerjakan.
        </li>
        <li>
          Setelah menyelesaikan Misi, Pejuang Misi mengajukan bukti-bukti
          penyelesaian Misi melalui aplikasi KitaLulus atau melalui media lain
          yang telah disediakan oleh KitaLulus terkait verifikasi penyelesaian
          Misi tersebut. KitaLulus akan memverifikasi bukti yang telah diajukan
          dan dengan segera menginformasikan hasil verifikasi Misi melalui email
          kepada Pejuang Misi dalam 7 (tujuh) hari kerja sejak bukti diajukan.
        </li>
        <li>
          Jika KitaLulus memverifikasi bahwa Pejuang Misi telah berhasil
          menyelesaikan Misi, Pejuang Misi dapat melakukan klaim <i>Rewards</i>{' '}
          dengan mengikuti semua instruksi yang KitaLulus berikan melalui email
          atau aplikasi KitaLulus.
        </li>
        <li>
          Pencairan <i>Rewards</i> atas penyelesaian Misi akan dilakukan 14
          (empat belas) hari kerja setelah Pejuang Misi memenuhi semua instruksi
          klaim atau pencairan yang KitaLulus. Biaya admin yang timbul atas
          transaksi ini ditanggung oleh Pejuang Misi.
        </li>
        <li>
          KitaLulus tidak bertanggung jawab atas kegagalan atau kesalahan
          pemberian <i>Rewards</i> yang disebabkan oleh kesalahan Pejuang Misi
          dalam mengisi data-data terkait pemberian <i>Rewards</i>.
        </li>
        <li>
          Jika dalam 2 (dua) bulan sejak hasil verifikasi diinformasikan Pejuang
          Misi tidak melakukan instruksi klaim <i>Rewards</i>, maka Rewards bagi
          Pejuang Misi atas penyelesaian Misi tersebut tidak lagi berlaku.
        </li>
        <li>
          Pejuang Misi tidak dapat mengikuti atau menyelesaikan Misi yang sama
          lebih dari 1 (satu) kali, kecuali dinyatakan lain melalui media lain
          yang berhubungan dengan Misi Seru tersebut.
        </li>
        <li>
          KitaLulus berhak untuk menolak pengerjaan Misi yang terindikasi
          mengandung spam atau penipuan. Keputusan penolakan pengerjaan Misi
          secara mutlak ditentukan oleh KitaLulus.
        </li>
        <li>
          Dengan berpartisipasi dalam Misi Seru, Pejuang Misi telah mengetahui
          dan menyetujui setiap ketentuan terkait Misi Seru yang ditetapkan oleh
          KitaLulus, baik dalam situs web/aplikasi KitaLulus maupun media lain
          yang berhubungan dengan Misi Seru.
        </li>
        <li>
          KitaLulus dapat sewaktu-waktu mengubah ketentuan terkait Misi Seru.
          Pejuang Misi disarankan untuk secara rutin memeriksa ketentuan terkait
          Misi seru agar dapat menyelesaikan Misi sesuai dengan ketentuan yang
          berlaku.
        </li>
        <li>
          Keputusan KitaLulus terkait Misi Seru sepenuhnya bersifat mutlak dan
          tidak dapat diganggu gugat.
        </li>
        <li>
          Pejuang Misi dapat mencairkan <i>Rewards</i> dengan cara-cara yang
          telah ditentukan oleh KitaLulus pada Layanan. Minimum pencairan
          Rewards yaitu sebesar Rp50.000 (lima puluh ribu Rupiah).
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        10. Pembelian dan Pembayaran
      </Typography>

      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Setiap pembelian produk apa pun yang tersedia di dalam Layanan hanya
          dapat dibayarkan melalui metode dan sistem pembayaran yang tercantum
          pada Layanan dan/atau diberlakukan berdasarkan S&K ini.
        </li>
        <li>
          Harga produk yang ditampilkan pada Layanan dapat mencakup potongan
          yang berlaku berdasarkan peraturan perundang-undangan terkait pajak
          yang berlaku di Indonesia dan/atau potongan lainnya yang wajib
          dibayarkan oleh KitaLulus dan/atau Pengguna.
        </li>
        <li>
          Jika harga produk yang ditampilkan pada Layanan belum mencakup
          potongan sebagaimana dimaksud pada huruf b, maka rincian harga secara
          lengkap (termasuk potongan) akan ditampilkan bagi pengguna sebelum
          pembayaran.
        </li>
        <li>
          Untuk menghindari keraguan, pajak serta potongan lainnya yang
          diberlakukan dalam pembelian produk pada Layanan dibebankan kepada
          Pengguna (jika ada).
        </li>
        <li>
          Tidak ada pengembalian biaya atas pembelian produk apa pun yang
          tersedia di dalam Layanan yang telah berhasil dibeli oleh Pengguna.
        </li>
        <li>
          KitaLulus dapat sewaktu-waktu menerapkan sistem perpanjangan otomatis
          pada fitur tertentu, di mana masa berlangganan Pengguna terhadap suatu
          fitur dalam Layanan yang telah dibeli olehnya akan secara otomatis
          diperpanjang setiap kali masa berlangganan habis. Tagihan yang timbul
          atas perpanjangan otomatis ini akan secara otomatis dibayarkan oleh
          Pengguna melalui metode pembayaran yang Pengguna gunakan untuk membeli
          Layanan tersebut. Dalam hal Pengguna metode pembayaran Pengguna tidak
          memiliki cukup saldo, perpanjangan otomatis akan dibatalkan.
        </li>
        <li>
          Pengguna dapat membatalkan perpanjangan otomatis fitur yang telah
          dibeli olehnya jika Pengguna tidak berkenan memperpanjang masa
          berlangganan. Untuk menghindari keraguan, masa berlangganan yang masih
          berjalan untuk fitur tersebut akan tetap berlanjut sesuai dengan masa
          berlangganan yang telah dibeli oleh Pengguna.
        </li>
        <li>
          Jika akun pada Layanan dihapus, maka masa berlangganan atas segala
          fitur yang masih berlaku bagi akun tersebut akan secara otomatis
          hangus tanpa adanya pengembalian biaya apa pun. Perpanjangan otomatis
          yang diberlakukan bagi fitur-fitur pada akun tersebut akan dibatalkan.
        </li>
        <li>
          KitaLulus dapat sewaktu-waktu membatalkan layanan apa pun yang telah
          dibeli oleh Pengguna. Dalam hal terjadinya pembatalan ini, Pengguna
          berhak untuk mendapatkan pengembalian biaya yang telah dibayarkan
          kepada KitaLulus untuk pembelian layanan secara pro-rata. Untuk
          menghindari keraguan, pengembalian biaya secara pro-rata yang dimaksud
          pada Pasal ini adalah jumlah bulan yang sudah dilewati pada masa
          berlaku layanan yang sedang berjalan dibagi jumlah bulan pada masa
          berlaku layanan tersebut, kemudian dikali total biaya atas layanan
          tersebut. Jika perhitungan pro-rata yang disebutkan sebelumnya tidak
          dapat diberlakukan untuk layanan tertentu karena satu dan lain hal,
          maka KitaLulus akan memberlakukan perhitungan pengembalian biaya
          secara seadil-adilnya.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        11. Tautan Pihak Ketiga
      </Typography>

      <Typography sx={{ py: '8px' }} variant="body1" textAlign="justify">
        KitaLulus mungkin akan menyertakan tautan ke situs lain (milik pihak
        ketiga) pada Layanan atau pun pada komunikasi secara personal dengan
        Pengguna. Kami akan menyertakan tautan ke situs lain dengan
        tujuan-tujuan yang terkait dengan peningkatan kualitas Layanan,
        memberikan informasi yang lebih lengkap terkait Perusahaan, sebagai
        referensi informasi, dan/atau dalam rangka pemenuhan kewajiban KitaLulus
        terhadap pemilik situs tersebut. KitaLulus akan menganalisa tautan milik
        pihak ketiga yang disertakan pada Layanan atau yang diberikan kepada
        Pengguna untuk mengetahui keamanan tautan tersebut bagi Pengguna, namun
        KitaLulus tidak dapat menjamin bahwa tautan milik pihak ketiga tersebut
        sepenuhnya aman dan tidak mengandung informasi yang bersifat sensitif.
      </Typography>

      <Typography sx={{ py: '8px' }}>
        Pengguna diperbolehkan untuk membagikan tautan milik pihak ketiga dalam
        Layanan, dengan ketentuan bahwa situs tersebut relevan dengan penggunaan
        Layanan oleh Pengguna serta tidak melanggar peraturan perundang-undangan
        yang berlaku atau melanggar kesusilaan. KitaLulus berhak untuk menghapus
        informasi tautan pihak ketiga yang dibagikan oleh Pengguna jika
        melanggar ketentuan yang berlaku pada pada S&K.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        12. Iklan dalam Layanan
      </Typography>
      <ol type="a" style={{ margin: 0, padding: '0 24px' }}>
        <li>
          Sehubungan dengan penyediaan Layanan secara gratis bagi sebagian
          Pengguna, kami akan menampilkan iklan di dalam Layanan yang akan
          mendukung kami untuk terus menyediakan dan mengembangkan Layanan.
        </li>
        <li>
          Pengiklan pada umumnya menetapkan kriteria tertentu dalam menampilkan
          iklannya dan secara khusus menampilkan iklan hanya kepada Pengguna
          yang memenuhi kriteria. Pengiklan mungkin akan menggunakan data yang
          secara internal mereka miliki terkait pola perilaku Anda pada layanan
          milik pengiklan dengan ketentuan yang telah Anda setujui sebelumnya
          untuk menentukan iklan yang relevan bagi Anda.
        </li>
        <li>
          Kami tidak melacak atau menyimpan data terkait pola perilaku atau
          minat Anda di luar Layanan kecuali yang telah dinyatakan pada S&K ini
          dan Kebijakan Privasi KitaLulus.
        </li>
        <li>
          Jika Anda menekan dan mengunjungi iklan yang ditampilkan pada Layanan,
          Anda setuju bahwa pengiklan mungkin akan mendapatkan data-data
          tertentu sebagaimana dijelaskan pada Kebijakan Privasi KitaLulus
          (secara khusus pada bagian “DENGAN SIAPA INFORMASI ANDA DIBERIKAN”).
        </li>
        <li>
          Kami dapat sewaktu-waktu menyediakan laporan untuk pengiklan yang
          berisi tentang kinerja iklannya guna membantu pengiklan tersebut
          memahami interaksi Pengguna orang dengan iklan yang ditampilan.
        </li>
      </ol>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        13. Yurisdiksi dan Hukum yang Berlaku
      </Typography>

      <Typography variant="body1" textAlign="justify">
        S&K ini diatur berdasarkan hukum yang pada saat ini berlaku di
        Indonesia. Dengan menggunakan Layanan, Pengguna sepakat untuk terikat
        pada yurisdiksi pengadilan Republik Indonesia.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        14. Ganti Rugi
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Pengguna setuju untuk tidak membebankan ganti rugi yang timbul dari atau
        terkait dengan penggunaan Layanan oleh pengguna atau kontennya atau
        pelanggaran pengguna terhadap S&K, untuk sejauh tidak dilarang oleh
        hukum yang berlaku. Ganti rugi ini akan menjadi tambahan untuk semua
        kewajiban pengguna lainnya berdasarkan S&K, dan tidak akan mengurangi
        hak atau upaya hukum lain yang tersedia menurut hukum untuk KitaLulus.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        15. Keterpisahan
      </Typography>

      <Typography variant="body1" textAlign="justify">
        Syarat dan ketentuan di dalam S&K ini akan dapat diberlakukan secara
        terpisah dengan satu sama lain dan keabsahan satu syarat dan/atau
        ketentuan tidak akan berpengaruh dengan ketentuan lainnya yang tidak
        sah. Dalam hal terdapat ketentuan atau bagian dari S&K yang tidak sesuai
        dengan ketentuan hukum yang berlaku, tidak sah atau tidak dapat
        dilaksanakan, keabsahan dan keberlakuan syarat dan ketentuan yang
        lainnya tidak akan terpengaruh, dan sebagai pengganti dari syarat atau
        ketentuan yang tidak sesuai dengan ketentuan hukum yang berlaku, tidak
        sah, atau tidak dapat dilaksanakan, akan ditambahkan sebagai bagian dari
        Syarat dan Ketentuan satu atau lebih ketentuan yang serupa dalam hal
        yang mungkin benar, sah, dan dapat dilaksanakan berdasarkan hukum yang
        berlaku.
      </Typography>

      <Typography
        variant="body1"
        sx={{ py: '8px', pt: '16px' }}
        textAlign="justify"
        fontWeight={600}
      >
        16. Pembaruan S&K
      </Typography>

      <Typography variant="body1" textAlign="justify">
        KitaLulus berhak untuk mengubah, menambah, menghapus atau mengubah S&K
        sewaktu-waktu tanpa pemberitahuan sebelumnya. Pengguna disarankan untuk
        meninjau S&K secara berkala. Apabila di kemudian hari Pengguna merasa
        keberatan dengan perubahan S&K pada Layanan, sebaiknya Pengguna
        menghentikan penggunaan. Penggunaan yang berlanjut merupakan bentuk
        penerimaan Pengguna atas semua perubahan yang diterapkan pada S&K ini.
      </Typography>
    </>
  );
};

export default TermCondition;
