import { setContext } from '@apollo/client/link/context';
import { firebaseAuth } from '~/config/firebase';
import { store } from '~/store';
import { ONBOARDING_STEPS } from '../constants';
import { setAuth } from '~/store/features/auth-slice';

export const authLink = setContext(async (_, { headers }) => {
  const onboarding = store.getState().onboarding;

  // please confirm if you want change this part
  const shouldRefreshToken =
    onboarding.currentStep === ONBOARDING_STEPS.SUBSCRIPTION_OFFER;

  const newAuthResponse =
    await firebaseAuth.currentUser?.getIdTokenResult(shouldRefreshToken);

  const isEmailVerified = newAuthResponse?.claims?.email_verified;
  const hasToken = newAuthResponse?.token;
  const isAuthenticated = isEmailVerified && hasToken;

  if (isAuthenticated)
    store.dispatch(
      setAuth({
        companyCode: newAuthResponse?.claims?.code,
      }),
    );

  const accessToken = isAuthenticated
    ? `ep ${newAuthResponse.token}`
    : undefined;

  return {
    headers: {
      ...headers,
      'Accept-Language': 'in',
      'apollo-require-preflight': true,
      authorization: accessToken,
    },
  };
});

export const authRefreshTokenLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Accept-Language': 'in',
      'apollo-require-preflight': true,
    },
  };
});
