import { Box, Button, Tooltip } from '@kitalulus/web-ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import AddIcon from '~/components/Icons/AddIcon';
import TruncateText from '~/components/TruncateText';
import { useAccountAccess } from '~/hooks/use-account-access';
import * as mpe from '~/utils/mixpanel';
import { VacancyCreateWrapperProps } from './';

const excludedPages = [
  '/vacancy/create',
  '/vacancy/create/:id',
  '/vacancy/:id/update',
  '/vacancy/:id/repost',
  '/vacancy/draft/:id',
];

const VacancyCreateWrapper = ({
  isCollapsed,
  isCollapseClick,
}: VacancyCreateWrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    isCompanyStatusOnHold,
    isCompanyStatusRejected,
    ableToCreateVacancy,
  } = useAccountAccess();

  const isCompanyStatusOnHoldOrRejected =
    isCompanyStatusOnHold || isCompanyStatusRejected;

  // hide the button on a certain pages
  const isHidden = useMemo(() => {
    return excludedPages.find((page) => matchPath(page, pathname));
  }, [pathname]);

  if (isHidden) return null;

  return (
    <Tooltip
      title={
        isCompanyStatusOnHoldOrRejected &&
        t('vacancy:tooltip.accountRestriction.vacancyCreateDisabled')
      }
      placement="bottom"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            textAlign: 'center',
            p: '12px',
            fontSize: '12px',
            maxWidth: '225px',
          },
        },
      }}
      arrow
    >
      <Box>
        <Button
          data-test-id="btnSidebarVacancyCreate"
          color="primary"
          variant="contained"
          startIcon={
            <AddIcon
              sx={{
                fontSize: isCollapsed ? '24px !important' : '22px !important',
              }}
            />
          }
          onClick={() => {
            mpe.vacancyCreate({
              location: isCollapseClick ? 'sideBarCollapse' : 'sideBarExpand',
            });
            navigate('/vacancy/create');
          }}
          disabled={!ableToCreateVacancy}
          fullWidth
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            height: isCollapsed ? '48px' : '36px',
            minWidth: 0,
            '.MuiButton-icon': { mr: 0, ml: 0 },
          }}
        >
          {!isCollapsed && (
            <TruncateText noOfLines={1}>{t('vacancy:createJob')}</TruncateText>
          )}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default VacancyCreateWrapper;
