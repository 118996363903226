import { useCallback, useMemo } from 'react';

import {
  EpCompanyVerificationStatus,
  EpJobVacancy,
  EpJobVacancyState,
} from '~/types/graphql/graphql';
import usePremium from './firebase/use-premium';
import { FETCH_STATUS } from '~/utils/constants';
import { useRoleAccess } from './use-role-access';
import { useAppSelector } from './use-store';

export const useAccountAccess = () => {
  const company = useAppSelector((state) => state.realtimeData.company);
  const { roleAccess: vacancyRoleAccess } = useRoleAccess('vacancy');
  const { isPremium: isCompanyPremium, hasJobPromoteQuotaLeft } = usePremium();

  const companyStatus = company?.data?.company
    ?.status as EpCompanyVerificationStatus;
  const isCompanyLoading = company.fetchStatus !== FETCH_STATUS.RESOLVED;

  const isCompanyStatusFraud =
    companyStatus === EpCompanyVerificationStatus.Fraud;
  const isCompanyStatusOnHold =
    companyStatus === EpCompanyVerificationStatus.OnHold;
  const isCompanyStatusRejected =
    companyStatus === EpCompanyVerificationStatus.Rejected;
  const isCompanyStatusUnverified =
    companyStatus === EpCompanyVerificationStatus.Unverified;

  const ableToCreateVacancy = useMemo(() => {
    if (!vacancyRoleAccess?.canCreate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToUpdateVacancy = useMemo(() => {
    if (!vacancyRoleAccess?.canUpdate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
        EpCompanyVerificationStatus.Unverified,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToCopyVacancy = useMemo(() => {
    if (!vacancyRoleAccess?.canCreate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
        EpCompanyVerificationStatus.Unverified,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToHighlightVacancy = useMemo(() => {
    if (!isCompanyPremium) return false;
    if (!hasJobPromoteQuotaLeft) return false;
    if (!vacancyRoleAccess?.canCreate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [
    vacancyRoleAccess,
    companyStatus,
    isCompanyPremium,
    hasJobPromoteQuotaLeft,
  ]);

  const ableToRepostVacancy = useMemo(() => {
    if (!vacancyRoleAccess?.canCreate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToShareVacancy = useMemo(() => {
    if (!vacancyRoleAccess?.canCreate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
        EpCompanyVerificationStatus.Unverified,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToUpdateVacancyDraft = useMemo(() => {
    if (!vacancyRoleAccess?.canUpdate) return false;
    if (
      [
        EpCompanyVerificationStatus.Rejected,
        EpCompanyVerificationStatus.OnHold,
      ].includes(companyStatus)
    )
      return false;

    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToDeleteVacancyDraft = useMemo(() => {
    if (!vacancyRoleAccess?.canUpdate) return false;
    return true;
  }, [vacancyRoleAccess, companyStatus]);

  const ableToUpdateSpecificVacancy = useCallback(
    ({ state }: Pick<EpJobVacancy, 'state'>) => {
      if (state === EpJobVacancyState.Closed) return false;
      return ableToUpdateVacancy;
    },
    [ableToUpdateVacancy],
  );

  const ableToShareSpecificVacancy = useCallback(
    ({ state }: Pick<EpJobVacancy, 'state'>) => {
      if (state !== EpJobVacancyState.Open) return false;
      return ableToShareVacancy;
    },
    [ableToShareVacancy],
  );

  const ableToRepostSpecificVacancy = useCallback(
    ({ state }: Pick<EpJobVacancy, 'state'>) => {
      if (state !== EpJobVacancyState.Closed) return false;
      return ableToRepostVacancy;
    },
    [ableToRepostVacancy],
  );

  const ableToHighlightSpecificVacancy = useCallback(
    ({ state }: Pick<EpJobVacancy, 'state'>) => {
      if (state !== EpJobVacancyState.Open) return false;
      return ableToHighlightVacancy;
    },
    [ableToHighlightVacancy],
  );

  return {
    isCompanyLoading,
    isCompanyPremium,
    isCompanyStatusFraud,
    isCompanyStatusOnHold,
    isCompanyStatusRejected,
    isCompanyStatusUnverified,
    ableToCreateVacancy,
    ableToCopyVacancy,
    ableToUpdateVacancy,
    ableToUpdateSpecificVacancy,
    ableToHighlightVacancy,
    ableToHighlightSpecificVacancy,
    ableToRepostVacancy,
    ableToRepostSpecificVacancy,
    ableToShareVacancy,
    ableToShareSpecificVacancy,
    ableToUpdateVacancyDraft,
    ableToDeleteVacancyDraft,
  };
};
