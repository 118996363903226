import { useLazyQuery } from '@apollo/client';
import {
  Box,
  brandColors,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcceptRejectPreview from '~/components/Applicant/AcceptRejectPreview';
import CloseIcon from '~/components/Icons/CloseIcon';
import EmailIconOutlined from '~/components/Icons/EmailIconOutlined';
import ReportIcon from '~/components/Icons/ReportIcon';
import LoadingButton from '~/components/LoadingButton';
import LoadingPage from '~/components/LoadingPage';
import SlideUpDialogTransition from '~/components/Transistions/SlideUpDialogTransition';
import { QUERY_JOB_APPLICATION_REJECTED_REASON_CATEGORIES } from '~/graphql/applicants';
import useApplicantDialog from '~/hooks/applicants/use-applicant-dialog';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { JobApplicationRejectedReasonCategoriesQuery } from '~/types/graphql/graphql';
import {
  ApplicantRejectDialogProps,
  ReasonsType,
} from './ApplicantRejectDialog.types';
import Header from './Header';
import LimitInfo from './LimitInfo';
import Options from './Options';

const ApplicantRejectDialog = ({
  content,
  ...props
}: ApplicantRejectDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { checkIsLoading } = useApplicantDialog();
  const [reasons, setReasons] = useState<ReasonsType[]>([]);
  const selected = reasons.filter((x) => x.checked);

  const [queryReasons, { data, loading: _loadingReasons }] =
    useLazyQuery<JobApplicationRejectedReasonCategoriesQuery>(
      QUERY_JOB_APPLICATION_REJECTED_REASON_CATEGORIES,
    );

  const handleClose = useCallback(() => {
    dispatch(closeDialog('applicantRejectDialog'));
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMDDown = useMediaQuery(theme.breakpoints.down('md'));
  const noMoreSelectable =
    reasons.filter((x) => !x.checked && !x.disabled).length === 0;

  const isSingleUpdate = content?.applicants.length === 1;

  const constructEmail = useMemo(() => {
    let email = '';
    const inEmail = new Set();
    selected.forEach((x) => {
      if (inEmail.has(x.reasonType)) return;
      if (x.reasonTitle) email += `<b>${x.reasonTitle}</b>\n`;
      if (x.reasonDescription) email += `${x.reasonDescription}\n\n`;
      inEmail.add(x.reasonType);
    });
    return email
      ? ` ${t('applicant:rejectDialog.withConsideration')}\n\n${email}`
      : '.\n\n';
  }, [reasons]);

  useEffect(() => {
    if (data?.jobApplicationRejectedReasonCategories)
      setReasons(
        data.jobApplicationRejectedReasonCategories?.map(
          (x) =>
            ({
              ...x,
              checked: false,
              disabled: false,
            }) as ReasonsType,
        ),
      );
  }, [data?.jobApplicationRejectedReasonCategories]);

  useEffect(() => {
    if (props.open) {
      queryReasons();
    } else {
      // uncheck options on dialog close
      setReasons(
        reasons.map((x) => ({
          ...x,
          checked: false,
          disabled: false,
        })),
      );
    }
  }, [props.open]);

  if (_loadingReasons) return <LoadingPage variant="overlay" />;
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      sx={{
        top: { xs: '10%', sm: 0 },
        borderRadius: '16px 16px 0px 0px',
      }}
      PaperProps={{ sx: { p: { xs: 0.3, sm: 2 } } }}
      scroll={'paper'}
      {...(isMobile && { TransitionComponent: SlideUpDialogTransition })}
      {...props}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          ml={{ xs: 0, sm: 3 }}
        >
          <Stack
            direction="row"
            gap={1}
            sx={{ flexBasis: '90%' }}
            alignItems="center"
          >
            <ReportIcon color="error" />
            <Typography component="div" variant="h5" color="text.primary">
              {t('applicant:rejectDialog.title')}
            </Typography>
          </Stack>
          <Stack sx={{ flexBasis: '10%', alignItems: 'end' }}>
            <IconButton
              sx={{ top: -5, right: { xs: -10, sm: -25 } }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Stack gap={2} justifyContent="center" alignItems="center">
          <Header sx={{ width: '100%' }} />
          <Typography variant="subtitle1" alignSelf="start" ml={3}>
            {t('applicant:rejectDialog.selectReason')}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="start"
            mx={3}
            gap={3}
          >
            <Stack
              flexBasis={1}
              flexGrow={0.75}
              direction="row"
              gap={1}
              alignSelf="start"
            >
              <EmailIconOutlined
                sx={{ width: 16, color: brandColors.neutral[700] }}
              />
              <Typography variant="body2" sx={{ color: brandColors.grey[600] }}>
                {t('applicant:rejectDialog.notice')}
              </Typography>
            </Stack>
            <LimitInfo
              totalSelected={selected.length}
              noMoreSelectable={noMoreSelectable}
              flexBasis={1}
              flexGrow={0.25}
              alignSelf={{ xs: 'end', sm: 'start' }}
              textAlign="right"
            />
          </Stack>
          <Stack
            spacing={{ xs: 1, md: 3 }}
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems="flex-start"
            px={{ xs: 0, md: 3 }}
            divider={!isMDDown && <Divider orientation="vertical" flexItem />}
          >
            <Box pb={4} flexShrink={0} alignSelf="center">
              <AcceptRejectPreview
                text={t('applicant:rejectDialog.preview', {
                  reasons: constructEmail,
                })}
              />
            </Box>

            {reasons && (
              <Options options={reasons} setReasons={setReasons}></Options>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow: theme.shadows[4],
          mb: { xs: -0.3, sm: -2 },
          px: 4,
          py: 2,
          mx: { xs: -0.3, sm: -2 },
          justifyContent: { xs: 'center', sm: 'end' },
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          sx={{ width: 150 }}
        >
          {t('common:cancelAlt')}
        </Button>
        <LoadingButton
          onClick={() => content?.onSubmit(selected)}
          loading={checkIsLoading(isSingleUpdate)}
          variant="contained"
          sx={{ width: 150 }}
        >
          {t('common:send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicantRejectDialog;
