import { useAppSelector } from '../use-store';

export const useCompanyPackageQuota = () => {
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const remainingQuota = realtimeData.packageByCompanyId.data?.remainingQuota;

  const quota = remainingQuota?.regular ?? 0;

  return { quota, hasQuota: quota > 0 };
};
