import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RefreshCountState = {
  userConfig?: number;
  user?: number;
  company?: number;
  vacancyList?: number;
  vacancyListSummary?: number;
  applicants?: number;
  candidateRecommendationDetail?: number;
  candidateRecommendationList?: number;
  candidateRecommendationSummary?: number;
};

export const refreshCountSlice = createSlice({
  name: 'refreshCount',
  initialState: {} as RefreshCountState,
  reducers: {
    refresh: <T extends keyof RefreshCountState>(
      state: RefreshCountState,
      action: PayloadAction<T>,
    ) => {
      state[action.payload] = (state[action.payload] ?? 0) + 1;
    },
    clearRefreshCount: () => ({}),
  },
});

export const { refresh, clearRefreshCount } = refreshCountSlice.actions;

export default refreshCountSlice.reducer;
