import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import * as mpe from '~/utils/mixpanel';
import {
  VacancyDraftAlreadyDeletedDialogProps,
  VacancyDraftAlreadyDeletedDialogVariantContent,
} from './';

const VacancyDraftAlreadyDeletedDialog = ({
  content: { variant, onDefaultConfirm, onInFormExit, onInFormCreateVacancy },
  ...props
}: VacancyDraftAlreadyDeletedDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(closeDialog('vacancyDraftAlreadyDeletedDialog'));
  }, []);

  const content =
    useMemo<VacancyDraftAlreadyDeletedDialogVariantContent | null>(() => {
      switch (variant) {
        case 'default':
          return {
            title: t('vacancy:draftAlreadyDeletedDialog.title'),
            body: t('vacancy:draftAlreadyDeletedDialog.body'),
            closeable: true,
            buttons: [
              {
                'data-test-id': 'btnVacancyDraftAlreadyDeletedDialogOk',
                children: t('common:ok'),
                variant: 'contained',
                color: 'primary',
                onClick: () => {
                  onDefaultConfirm?.();
                  handleClose();
                },
              },
            ],
          };

        case 'in-form':
          return {
            title: t('vacancy:draftAlreadyDeletedInFormDialog.title'),
            body: t('vacancy:draftAlreadyDeletedInFormDialog.body'),
            closeable: false,
            buttons: [
              {
                'data-test-id': 'btnVacancyDraftAlreadyDeletedDialogExit',
                children: t(
                  'vacancy:draftAlreadyDeletedInFormDialog.buttonExit',
                ),
                onClick: () => {
                  onInFormExit?.();
                  handleClose();
                },
              },
              {
                'data-test-id':
                  'btnVacancyDraftAlreadyDeletedDialogCreateVacancy',
                children: t(
                  'vacancy:draftAlreadyDeletedInFormDialog.buttonCreateVacancy',
                ),
                variant: 'contained',
                color: 'primary',
                onClick: () => {
                  mpe.vacancyCreate({ location: 'deletedDraft' });

                  onInFormCreateVacancy?.();
                  handleClose();
                },
              },
            ],
          };

        default:
          return null;
      }
    }, [
      variant,
      handleClose,
      onDefaultConfirm,
      onInFormExit,
      onInFormCreateVacancy,
    ]);

  if (!content) return null;

  return (
    <Dialog
      data-test-id="ctVacancyDraftAlreadyDeletedDialog"
      sx={{
        '.MuiDialog-paper': {
          maxWidth: 480,
          px: '24px',
          pb: '24px',
          mx: 2,
          gap: 1.25,
        },
        '.MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root':
          { p: 0, pt: '12px' },
        '.MuiDialogActions-root > :not(:first-of-type)': { ml: 0 },
      }}
      {...props}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          data-test-id="ctVacancyDraftAlreadyDeletedDialogTitle"
          variant="h6"
          color="text.primary"
        >
          {content.title}
        </Typography>
        {content.closeable && (
          <IconButton
            data-test-id="btnVacancyDraftAlreadyDeletedDialogClose"
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: 'text.primary',
              position: 'relative',
              top: '-6px',
              right: '-6px',
            }}
          >
            <CloseIcon sx={{ fontSize: 22 }} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          data-test-id="ctVacancyDraftAlreadyDeletedDialogBody"
          variant="body1"
          color="text.secondary"
        >
          {content.body}
        </DialogContentText>
      </DialogContent>

      {content.buttons.length >= 1 && (
        <DialogActions sx={{ gap: 1 }}>
          {content.buttons.map((btn, i) => (
            <Button
              key={i}
              variant="text"
              color="inherit"
              {...btn}
              sx={{ fontWeight: 600, textTransform: 'none', ...btn.sx }}
            />
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VacancyDraftAlreadyDeletedDialog;
