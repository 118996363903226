import { Box, Button, CircularProgress } from '@kitalulus/web-ui-kit';
import { MouseEventHandler, useCallback } from 'react';

import useDependentState from '~/hooks/use-dependent-state';
import { LoadingButtonProps } from './LoadingButton.types';

const spinnerSizeMap = {
  small: 12,
  medium: 16,
  large: 20,
};

const LoadingButton = ({
  loading,
  loadingByOnClick,
  onClick,
  ...props
}: LoadingButtonProps) => {
  const [isLoading, setIsLoading] = useDependentState(loading);

  const spinnerSize =
    spinnerSizeMap[props.size ?? 'medium'] ?? spinnerSizeMap['medium'];

  const modifiedOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      if (!onClick) return;
      setIsLoading(true);
      await onClick?.(e);
      setIsLoading(false);
    },
    [onClick],
  );

  return (
    <Button
      onClick={loadingByOnClick ? modifiedOnClick : onClick}
      {...props}
      disabled={props.disabled || isLoading}
      sx={{
        ...props.sx,
        '.MuiButton-icon': {
          opacity: isLoading ? 0 : 1,
          ...(props.sx as any)?.['.MuiButton-icon'],
        },
      }}
    >
      <Box sx={{ opacity: isLoading ? 0 : 1 }} zIndex={1}>
        {props.children}
      </Box>
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress color="inherit" size={spinnerSize} />
        </Box>
      )}
    </Button>
  );
};

export default LoadingButton;
