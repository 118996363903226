import {
  Box,
  Button,
  MenuPopover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@kitalulus/web-ui-kit';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChevronDownIcon from '~/components/Icons/ChevronDownIcon';
import FiSsArrowRight from '~/components/Icons/FiSsArrowRight';
import FiSsChartPieAlt from '~/components/Icons/FiSsChartPieAlt';
import InfoIcon from '~/components/Icons/InfoIcon';
import ProgressBar from '~/components/Layout/VacancyQuotaDetails/ProgressBar';
import { useCompanyPackageQuota } from '~/hooks/company-package/use-company-package-quota';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setDialog } from '~/store/views/dialogs-slice';
import { Nullable } from '~/types';
import { JOB_TYPES } from '~/utils/constants/vacancy';
import { kitalulusLink } from '~/utils/kitalulus-link';
import * as mpe from '~/utils/mixpanel';

const VacancyQuotaDetails = () => {
  const [triggerElem, setTriggerElem] = useState<Nullable<HTMLElement>>(null);
  const realtimeData = useAppSelector((state) => state.realtimeData);
  const remainingQuota = realtimeData?.packageByCompanyId?.data?.remainingQuota;
  const totalQuota = realtimeData?.packageByCompanyId?.data?.totalQuota;
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { hasQuota } = useCompanyPackageQuota();

  const closePopover = () => {
    setTriggerElem(null);
  };

  const onLearnMoreClicked = () => {
    closePopover();
  };

  const quotas = useMemo(() => {
    const growingRemaining = remainingQuota?.growing ?? 0;
    const growingTotal = totalQuota?.growing ?? 0;
    const regularRemaining = remainingQuota?.regular ?? 0;
    const regularTotal = totalQuota?.regular ?? 3;
    const priorityRemaining = remainingQuota?.priority ?? 0;
    const priorityTotal = totalQuota?.priority ?? 0;

    const growingUsed = growingTotal - growingRemaining;
    const regularUsed = regularTotal - regularRemaining;
    const priorityUsed = priorityTotal - priorityRemaining;

    return [
      {
        title: t('dashboard:vacancyQuotaDetails.postingQuota'),
        used: regularUsed,
        max: regularTotal,
        helpText: t('dashboard:vacancyQuotaDetails.postingQuotaHelp'),
        learnMoreLink: kitalulusLink.helpCenterJobPostingQuota,
      },
      {
        title: JOB_TYPES.GROWING_JOB,
        used: growingUsed,
        max: growingTotal,
        helpText: t('dashboard:vacancyQuotaDetails.growingHelp'),
        learnMoreLink: kitalulusLink.helpCenterJobCategory,
        hidden: true,
      },
      {
        title: JOB_TYPES.REGULAR_JOB,
        used: regularUsed,
        max: regularTotal,
        helpText: t('dashboard:vacancyQuotaDetails.regularHelp'),
        learnMoreLink: kitalulusLink.helpCenterJobCategory,
        hidden: true,
      },
      {
        title: JOB_TYPES.PRIORITY_JOB,
        used: priorityUsed,
        max: priorityTotal,
        helpText: t('dashboard:vacancyQuotaDetails.priorityHelp'),
        learnMoreLink: kitalulusLink.helpCenterJobCategory,
        hidden: true,
      },
    ];
  }, [remainingQuota, totalQuota]);

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        sx={{
          fontWeight: 400,
          ...(!isMobile && { width: 328 }),
          ...(isMobile && {
            '& .MuiButton-startIcon': {
              m: 0,
            },
          }),
          '& .MuiButton-endIcon': {
            ml: 'auto',
          },
        }}
        startIcon={
          <Box display="flex" width="auto" position="relative">
            <FiSsChartPieAlt
              sx={{ width: 24, height: 24, ...(!isMobile && { m: 0 }) }}
            />
            {isMobile && !hasQuota && (
              <InfoIcon
                color="warning"
                sx={{
                  fontSize: '14px',
                  position: 'absolute',
                  bottom: '0px',
                  right: '-6px',
                }}
              />
            )}
          </Box>
        }
        endIcon={
          !isMobile && (
            <ChevronDownIcon
              sx={{
                fontSize: '12px !important',
                transition: 'transform 0.3s',
                ...(triggerElem && { transform: 'rotate(180deg)' }),
              }}
            />
          )
        }
        onClick={(e) => {
          mpe.vacancyQuotaCheck({ action: 'check' });
          setTriggerElem(e.currentTarget);
        }}
      >
        {!isMobile && (
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" color="inherit">
              {t('dashboard:vacancyQuotaDetail')}
            </Typography>
            {!hasQuota && (
              <InfoIcon color="warning" sx={{ fontSize: '24px' }} />
            )}
          </Stack>
        )}
      </Button>
      <MenuPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!triggerElem}
        anchorEl={triggerElem}
        onClose={closePopover}
        sx={{
          '& .MuiPaper-root': {
            width: 384,
            p: '24px',
            maxWidth: '100vw',
            '@media (max-width:400px)': {
              left: '0 !important',
            },
          },
        }}
        arrow="none"
      >
        <Stack gap="24px">
          <Stack gap="24px">
            <Typography
              variant="h6"
              data-test-id={`lbDashboardVacancyQuotaDetailsTitle`}
            >
              {t('dashboard:vacancyQuotaDetails.title')}
            </Typography>
            {quotas.map(
              (q) =>
                !q.hidden && (
                  <ProgressBar
                    key={q.title}
                    {...q}
                    onLearnMoreClicked={onLearnMoreClicked}
                  />
                ),
            )}
          </Stack>

          {/* CTA  */}
          <Stack direction="row" justifyContent="space-between">
            <Button
              data-test-id={`lbDashboardVacancyQuotaDetailsCtaUpgrade`}
              variant="contained"
              sx={{
                fontWeight: 400,
              }}
              onClick={() => {
                mpe.premiumOffering({
                  linkPosition: 'dashboard',
                });
                mpe.vacancyQuotaCheck({ action: 'upgrade' });
                dispatch(
                  setDialog({
                    subscriptionPlanDialog: {
                      open: true,
                      content: {
                        linkPosition: 'dashboard',
                      },
                    },
                  }),
                );
                closePopover();
              }}
            >
              {t('common:upgradePackage')}
            </Button>
            <Button
              data-test-id={`lbDashboardVacancyQuotaDetailsCtaDetail`}
              variant="text"
              color="inherit"
              size="small"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.75rem',
                fontWeight: 400,
                textTransform: 'none',
              }}
              onClick={() => {
                mpe.vacancyQuotaCheck({ action: 'detail' });
                closePopover();
                navigate('/account/package-detail');
              }}
            >
              {t('dashboard:vacancyQuotaDetails.packageDetail')}
              <FiSsArrowRight sx={{ fontSize: '24px' }} />
            </Button>
          </Stack>
        </Stack>
      </MenuPopover>
    </>
  );
};

export default VacancyQuotaDetails;
