import { ComponentType, useEffect } from 'react';
import ReactGA from 'react-ga4';

import GlobalDialogs from '~/components/GlobalDialogs';
import GlobalSnackbars from '~/components/GlobalSnackbars';
import LoadingPage from '~/components/LoadingPage';
import SnackbarPersist from '~/components/SnackbarPersist/SnackbarPersist';
import { useFirebaseInit } from '~/hooks/firebase/use-firebase-init';
import { useCompany, useCompanyAccManager } from '~/hooks/use-company';
import usePreloadedImages from '~/hooks/use-preloaded-images';
import { useAppSelector } from '~/hooks/use-store';
import { useUser } from '~/hooks/use-user';
import { FETCH_STATUS } from '~/utils/constants';
import { Helmet } from 'react-helmet';

const isConfigLoading = () => {
  const user = useAppSelector((state) => state.user);

  const firebaseRemoteConfig = useAppSelector(
    (state) => state.firebaseRemoteConfig,
  );

  const isUserLoading = user
    ? user?.fetchStatus === FETCH_STATUS.IDLE ||
      user?.fetchStatus === FETCH_STATUS.PENDING
    : false;

  const isFirebaseLoading =
    firebaseRemoteConfig.fetchStatus === FETCH_STATUS.IDLE ||
    firebaseRemoteConfig.fetchStatus === FETCH_STATUS.PENDING;

  return isUserLoading || isFirebaseLoading;
};

export function withMainData<T extends JSX.IntrinsicAttributes>(
  Content: ComponentType<T>,
): ComponentType<T> {
  return function MainDataContent(props) {
    // make sure these data loaded before we render the content
    useFirebaseInit();
    useUser();
    useCompany();
    useCompanyAccManager();

    // cache image for offline purposes
    usePreloadedImages(
      'disconnectedImage',
      '/illustrations/disconnected-illustration.webp',
    );

    useEffect(() => {
      ReactGA.send(window.location.pathname);
    }, [window.location.pathname]);

    if (isConfigLoading()) {
      return <LoadingPage variant="overlay" />;
    }

    return (
      <>
        <Helmet>
          <link
            rel="preload"
            href="/illustrations/disconnected-illustration.webp"
            as="image"
            fetchpriority="high"
          />
        </Helmet>
        <GlobalDialogs />
        <SnackbarPersist />
        <GlobalSnackbars>
          <Content {...props} />
        </GlobalSnackbars>
      </>
    );
  };
}
