import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Unsubscribe } from 'firebase/firestore';
import { Nullable } from '~/types';
import {
  EpCompanyVerificationStatus,
  EpUserSubscriptionPlanTypeEnum,
} from '~/types/graphql/graphql';
import { FETCH_STATUS } from '~/utils/constants';

interface IPackage {
  name: string;
  subscription: Nullable<EpUserSubscriptionPlanTypeEnum>;
  isPremium: boolean;
  current: string;
  canUpgrade: boolean;
  isDisplayInfo: boolean;
  expiryDate: string;
  remainingDays: number;
  freeTrialStatus: string;
}

export interface IPackageV2 {
  currentHighestPackageEnum: string;
  currentHighestPackageName: string;
  hasCRE: boolean;
  hasCSE: boolean;
  isPremium: boolean;
  remainingQuota: IRemainingQuota;
  totalQuota: ITotalQuota;
  hasAlmostExpired: boolean;
  canInviteInterview: boolean;
  canAccessVacancyExternalLink: boolean;
}

export type TFirestoreHighlighted = {
  current: number;
  max: number;
};

export interface IRemainingQuota {
  growing: number;
  priority: number;
  regular: number;
}
interface ITotalQuota {
  growing: number;
  priority: number;
  regular: number;
}

interface ICopywriting {
  errorMessage: string;
  announcementMessage: string;
}

interface IJobPromoted {
  max: number;
  hasExceeded: boolean;
  current: number;
}

interface ICompany {
  status: Nullable<EpCompanyVerificationStatus>;
  name: string;
  code: string;
}

interface IVacancies {
  [key: string]: IVacanciesData;
}
export interface IVacanciesData {
  current: number;
  max: number;
  hasExceeded: boolean;
}

interface IJobPublished {
  current: number;
  max: number;
  hasExceeded: boolean;
}

interface FirestoreCompany {
  lastUpdateAt: string;
  package: IPackage;
  copywriting: ICopywriting;
  jobPromoted: IJobPromoted;
  company: ICompany;
  jobPublished: IJobPublished;
}

export interface RealtimeDataState {
  company: {
    fetchStatus: FETCH_STATUS;
    data: FirestoreCompany;
    error: string | null;
    unsubscribe: Nullable<Unsubscribe>;
  };
  vacancyByCompanyId: {
    fetchStatus: FETCH_STATUS;
    data: IVacancies;
    error: string | null;
    unsubscribe: Nullable<Unsubscribe>;
  };
  packageByCompanyId: {
    fetchStatus: FETCH_STATUS;
    data: Nullable<IPackageV2>;
    error: Nullable<string>;
    unsubscribe: Nullable<Unsubscribe>;
  };
  highlightedByCompanyId: {
    fetchStatus: FETCH_STATUS;
    data: Nullable<TFirestoreHighlighted>;
    error: Nullable<string>;
    unsubscribe: Nullable<Unsubscribe>;
  };
}

const initialState: RealtimeDataState = {
  company: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: {
      lastUpdateAt: '',
      package: {
        name: '',
        subscription: null,
        isPremium: false,
        current: '',
        canUpgrade: false,
        isDisplayInfo: false,
        expiryDate: '',
        remainingDays: 0,
        freeTrialStatus: '',
      },
      copywriting: {
        errorMessage: '',
        announcementMessage: '',
      },
      jobPromoted: {
        max: 0,
        hasExceeded: false,
        current: 0,
      },
      company: {
        status: null,
        name: '',
        code: '',
      },
      jobPublished: {
        current: 0,
        max: 0,
        hasExceeded: false,
      },
    },
    error: null,
    unsubscribe: null,
  },
  vacancyByCompanyId: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: {},
    error: null,
    unsubscribe: null,
  },
  packageByCompanyId: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
    unsubscribe: null,
  },
  highlightedByCompanyId: {
    fetchStatus: FETCH_STATUS.IDLE,
    data: null,
    error: null,
    unsubscribe: null,
  },
};

const realtimeDataSlice = createSlice({
  name: 'realtimeData',
  initialState,
  reducers: {
    setRealtimeDataCompany: (
      state,
      action: PayloadAction<{ companyCode: string; data: any }>,
    ) => {
      const { data } = action.payload;
      state.company.data = data;
      state.company.fetchStatus = FETCH_STATUS.RESOLVED;
      state.company.error = null;
    },
    setRealtimeDataCompanyFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.company.fetchStatus = action.payload;
    },
    setRealtimeDataCompanyError: (state, action: PayloadAction<string>) => {
      state.company.error = action.payload;
      state.company.fetchStatus = FETCH_STATUS.FAILED;
    },
    setRealtimeDataCompanyUnsubscribe: (
      state,
      action: PayloadAction<Nullable<Unsubscribe>>,
    ) => {
      state.company.unsubscribe = action.payload;
    },
    setRealtimeDataVacancyByCompanyId: (
      state,
      action: PayloadAction<IVacancies>,
    ) => {
      state.vacancyByCompanyId.data = {
        ...state.vacancyByCompanyId.data,
        ...action.payload,
      };
      state.vacancyByCompanyId.fetchStatus = FETCH_STATUS.RESOLVED;
      state.company.error = null;
    },
    setRealtimeDataVacancyByCompanyIdFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.vacancyByCompanyId.fetchStatus = action.payload;
    },
    setRealtimeDataVacancyByCompanyIdError: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.vacancyByCompanyId.error = action.payload;
      state.vacancyByCompanyId.fetchStatus = FETCH_STATUS.FAILED;
    },
    setRealtimeDataVacancyByCompanyIdUnsubscribe: (
      state,
      action: PayloadAction<Nullable<Unsubscribe>>,
    ) => {
      state.vacancyByCompanyId.unsubscribe = action.payload;
    },
    setRealtimeDataPackageByCompanyId: (
      state,
      action: PayloadAction<IPackageV2>,
    ) => {
      state.packageByCompanyId.data = {
        ...state.packageByCompanyId.data,
        ...action.payload,
      };
      state.packageByCompanyId.fetchStatus = FETCH_STATUS.RESOLVED;
      state.company.error = null;
    },
    setRealtimeDataPackageByCompanyIdFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.packageByCompanyId.fetchStatus = action.payload;
    },
    setRealtimeDataPackageByCompanyIdError: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.packageByCompanyId.error = action.payload;
      state.packageByCompanyId.fetchStatus = FETCH_STATUS.FAILED;
    },
    setRealtimeDataPackageByCompanyIdUnsubscribe: (
      state,
      action: PayloadAction<Nullable<Unsubscribe>>,
    ) => {
      state.packageByCompanyId.unsubscribe = action.payload;
    },
    setRealtimeDataHighlightedByCompanyId: (
      state,
      action: PayloadAction<TFirestoreHighlighted>,
    ) => {
      state.highlightedByCompanyId.data = action.payload;
      state.highlightedByCompanyId.fetchStatus = FETCH_STATUS.RESOLVED;
      state.highlightedByCompanyId.error = null;
    },
    setRealtimeDataHighlightedByCompanyIdFetchStatus: (
      state,
      action: PayloadAction<FETCH_STATUS>,
    ) => {
      state.highlightedByCompanyId.fetchStatus = action.payload;
    },
    setRealtimeDataHighlightedByCompanyIdError: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.highlightedByCompanyId.error = action.payload;
      state.highlightedByCompanyId.fetchStatus = FETCH_STATUS.FAILED;
    },
    setRealtimeDataHighlightedByCompanyIdUnsubscribe: (
      state,
      action: PayloadAction<Nullable<Unsubscribe>>,
    ) => {
      state.highlightedByCompanyId.unsubscribe = action.payload;
    },
    clearRealtimeData: () => initialState,
  },
});

export const {
  setRealtimeDataCompany,
  setRealtimeDataCompanyFetchStatus,
  setRealtimeDataCompanyError,
  setRealtimeDataCompanyUnsubscribe,
  setRealtimeDataVacancyByCompanyId,
  setRealtimeDataVacancyByCompanyIdFetchStatus,
  setRealtimeDataVacancyByCompanyIdError,
  setRealtimeDataVacancyByCompanyIdUnsubscribe,
  setRealtimeDataPackageByCompanyId,
  setRealtimeDataPackageByCompanyIdFetchStatus,
  setRealtimeDataPackageByCompanyIdError,
  setRealtimeDataPackageByCompanyIdUnsubscribe,
  setRealtimeDataHighlightedByCompanyId,
  setRealtimeDataHighlightedByCompanyIdFetchStatus,
  setRealtimeDataHighlightedByCompanyIdError,
  setRealtimeDataHighlightedByCompanyIdUnsubscribe,
  clearRealtimeData,
} = realtimeDataSlice.actions;

export default realtimeDataSlice.reducer;
