import { SvgIcon, SvgIconProps } from '@kitalulus/web-ui-kit';

const AddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <path d="M15.9268 9.76693H10.9268V4.76693C10.9268 4.30669 10.5537 3.93359 10.0934 3.93359C9.63319 3.93359 9.26009 4.30669 9.26009 4.76693V9.76693H4.26009C3.79985 9.76693 3.42676 10.14 3.42676 10.6003C3.42676 11.0605 3.79985 11.4336 4.26009 11.4336H9.26009V16.4336C9.26009 16.8938 9.63319 17.2669 10.0934 17.2669C10.5537 17.2669 10.9268 16.8938 10.9268 16.4336V11.4336H15.9268C16.387 11.4336 16.7601 11.0605 16.7601 10.6003C16.7601 10.14 16.387 9.76693 15.9268 9.76693Z" />
    </SvgIcon>
  );
};

export default AddIcon;
