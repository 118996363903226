import { Stack, Typography, brandColors, Button } from '@kitalulus/web-ui-kit';
import { useTranslation } from 'react-i18next';

import CrownIcon from '~/components/Icons/CrownIcon';
import TruncateText from '~/components/TruncateText';
import { useAppDispatch, useAppSelector } from '~/hooks/use-store';
import { setDialog } from '~/store/views/dialogs-slice';
import { FETCH_STATUS } from '~/utils/constants';
import * as mpe from '~/utils/mixpanel';
import { FreePlanBannerProps } from '.';

const FreePlanBanner = ({ isCollapsed, ...props }: FreePlanBannerProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { packageByCompanyId } = useAppSelector((state) => state.realtimeData);

  const isInitialized =
    packageByCompanyId.fetchStatus === FETCH_STATUS.RESOLVED;
  const isPremium = !!packageByCompanyId.data?.isPremium;

  if (!isInitialized) return null;
  if (isPremium) return null;

  return (
    <Stack
      data-test-id="ctPremiumBannerSquare"
      p={2}
      gap={2}
      bgcolor={brandColors.grey[200]}
      sx={{ borderRadius: 1 }}
      {...props}
    >
      <Stack gap={0.75}>
        <CrownIcon sx={{ color: brandColors.secondary[400] }} />

        {!isCollapsed && (
          <Stack gap={0.75}>
            <TruncateText noOfLines={1}>
              <Typography
                data-test-id="lbPremiumBannerSquareTitle"
                variant="subtitle2"
              >
                {t('common:freePlanBannerTitle')}
              </Typography>
            </TruncateText>

            <TruncateText noOfLines={2}>
              <Typography
                data-test-id="lbPremiumBannerSquareSubtitle"
                variant="body2"
                pb="6px"
              >
                {t('common:freePlanBannerSubtitle')}
              </Typography>
            </TruncateText>
          </Stack>
        )}
      </Stack>

      {!isCollapsed && (
        <Button
          data-test-id="btnPremiumBannerSquareUpgradePackage"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() => {
            mpe.premiumOffering({ linkPosition: 'limitBasicVacancy' });
            dispatch(
              setDialog({
                subscriptionPlanDialog: {
                  open: true,
                  content: { linkPosition: 'limitBasicVacancy' },
                },
              }),
            );
          }}
        >
          <TruncateText noOfLines={1}>
            {t('common:upgradePackage')}
          </TruncateText>
        </Button>
      )}
    </Stack>
  );
};

export default FreePlanBanner;
