import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  brandColors,
} from '@kitalulus/web-ui-kit';

import CloseIcon from '~/components/Icons/CloseIcon';
import LinkedInSquareIcon from '~/components/Icons/LinkedInSquareIcon';
import FiRsLink from '~/components/Icons/FiRsLink';
import { useAppDispatch } from '~/hooks/use-store';
import { useCopyText } from '~/hooks/use-copy-text';
import { generateLinkedInShareLink } from '~/utils/shareLink';
import * as mpe from '~/utils/mixpanel';

import { VacancyShareDialogProps } from './VacancyShareDialog.types';
import { MixpanelVacancyShareMedia } from '~/types/mixpanel';
import { setSnackbar } from '~/store/views/snackbar-slice';
import { convertDateToISOString } from '~/utils/helper';
import { useVacancyShareDialog } from '~/hooks/vacancy/use-vacancy-share-dialog';
import { VACANCY_UTM } from '~/utils/constants/vacancy';
import { useAccountAccess } from '~/hooks/use-account-access';

const VacancyShareDialog = ({
  content,
  open,
  onClose,
}: VacancyShareDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { copy } = useCopyText();
  const { closeVacancyShareDialog } = useVacancyShareDialog();
  const {
    isCompanyStatusFraud,
    isCompanyStatusRejected,
    isCompanyStatusUnverified,
  } = useAccountAccess();

  const isCopyFail =
    isCompanyStatusFraud ||
    isCompanyStatusRejected ||
    isCompanyStatusUnverified;

  const showFailedCopy = () => {
    dispatch(
      setSnackbar({
        message: t('vacancy:dialog.shareVacancy.failedCopy'),
        severity: 'error',
      }),
    );
  };

  const handleOnShareToLinkedIn = useCallback(() => {
    mpe.vacancyShare({
      media: MixpanelVacancyShareMedia.linkedin,
      vacancyCode: content.vacancyCode,
      vacancyName: content.vacancyTitle,
      vacancyCreatedAt: convertDateToISOString(content.vacancyCreatedAt),
      vacancyClosingDate: convertDateToISOString(content.vacancyClosingDate),
      linkPosition: content.linkPosition,
    });

    if (isCopyFail) return showFailedCopy();

    window.open(generateLinkedInShareLink(content?.webLink), '_blank');
  }, [content?.webLink]);

  const handleOnCopyLink = useCallback(() => {
    mpe.vacancyShare({
      media: MixpanelVacancyShareMedia.copy_link,
      vacancyCode: content.vacancyCode,
      vacancyName: content.vacancyTitle,
      vacancyCreatedAt: convertDateToISOString(content.vacancyCreatedAt),
      vacancyClosingDate: convertDateToISOString(content.vacancyClosingDate),
      linkPosition: content.linkPosition,
    });

    if (isCopyFail) return showFailedCopy();

    copy(
      `${content?.webLink}${VACANCY_UTM.COPY_LINK}`,
      t('vacancy:dialog.shareVacancy.successCopied'),
    );
  }, [content?.webLink]);

  const handleOnClose = () => {
    closeVacancyShareDialog();
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: { xs: 327, sm: 480 },
          p: 3,
          m: 0,
          gap: 3,
        },
        '.MuiDialogTitle-root': {
          fontSize: 16,
          fontWeight: 600,
          p: 0,
          m: 0,
          overflowWrap: 'break-word',
        },
        '.MuiDialogContent-root': {
          fontSize: 14,
          p: 0,
        },
        '.MuiDialogActions-root': {
          justifyContent: 'unset',
          p: 0,
          gap: { xs: '6px', sm: '12px' },
          flexDirection: { xs: 'column', sm: 'row' },
        },
      }}
      data-test-id="ctVacancyShare"
    >
      <DialogTitle
        data-test-id="lbVacancyShareTitle"
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="inherit"
          fontWeight="inherit"
          color="text.primary"
        >
          {content?.title ?? t('vacancy:dialog.shareVacancy.title')}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{ color: 'text.primary' }}
          data-test-id="btnVacancyShareClose"
        >
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent data-test-id="lbVacancyShareContent">
        {content?.title ? (
          <>
            <Typography color="text.primary" fontWeight={600}>
              {t('vacancy:dialog.shareVacancy.title')}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {t('vacancy:dialog.shareVacancy.message')}
            </Typography>
          </>
        ) : (
          t('vacancy:dialog.shareVacancy.message')
        )}
      </DialogContent>
      <DialogActions data-test-id="lbVacancyShareActions">
        <Button
          variant="contained"
          size="large"
          startIcon={<LinkedInSquareIcon sx={{ '&&': { fontSize: 32 } }} />}
          onClick={handleOnShareToLinkedIn}
          sx={{
            fontWeight: 600,
            textTransform: 'unset',
            height: 48,
            width: { xs: 277, sm: 230 },
          }}
          data-test-id="btnVacancyShareToLinkedIn"
        >
          {t('vacancy:dialog.shareVacancy.shareToLinkedIn')}
        </Button>
        <Typography sx={{ color: 'text.secondary' }}>
          {t('common:or')}
        </Typography>
        <Button
          variant="outlined"
          size="large"
          startIcon={
            <FiRsLink sx={{ '&&': { fontSize: 14, color: 'text.primary' } }} />
          }
          onClick={handleOnCopyLink}
          sx={{
            color: 'text.primary',
            textTransform: 'unset',
            fontWeight: 600,
            height: 48,
            width: { xs: 277, sm: 150 },
            borderColor: brandColors.neutral[300],
            '&&': { ml: 0 },
            ':hover': {
              color: 'text.primary',
              backgroundColor: brandColors.neutral[300],
              borderColor: brandColors.neutral[300],
            },
          }}
          data-test-id="btnVacancyShareCopyLink"
        >
          {t('common:copyLink')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VacancyShareDialog;
