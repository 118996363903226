export const kitalulusLink = {
  instagram: 'https://www.instagram.com/kitalulus/',
  tikTok: 'https://www.tiktok.com/@kitalulus?is_from_webapp=1&sender_device=pc',
  x: 'https://x.com/KitaLulus?s=20',
  linkedIn: 'https://www.linkedin.com/company/kitalulusid/mycompany/',
  youtube: 'https://www.youtube.com/c/KitaLulus',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.kitalulus',
  helpCenter: 'https://help.kitalulus.com/',
  helpCenterJobCategory:
    'https://help.kitalulus.com/article/28-apa-saja-jenis-kategori-lowongan-di-dasbor-perusahaan-kitalulus-penjelasan-untuk-tiap-kategorinya',
  helpCenterJobQuota:
    'https://help.kitalulus.com/article/31-apa-yang-dimaksud-dengan-kuota-posting-lowongan-kuota-job-posting-dan-bagaimana-cara-menghitungnya',
  helpCenterAccountDeleted:
    'https://help.kitalulus.com/article/67-mengapa-muncul-peringatan-sistem-mendeteksi-email-ini-terdaftar-pada-akun-yang-telah-nonaktif-saat-masuk-daftar-ke-dasbor-perusahaan-kitalulus',
  helpCenterJobPostingQuota:
    'https://help.kitalulus.com/article/72-apa-yang-dimaksud-dengan-kuota-posting-anda-di-detail-kuota-lowongan-pada-dasbor-perusahaan-kitalulus',
  helpCenterVacancyExternalLink:
    'https://help.kitalulus.com/article/85-apa-itu-jalur-lamaran',
};
