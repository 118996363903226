import { useMemo } from 'react';

import { MenuNames } from '~/types/menu';
import { useAppSelector } from './use-store';

export const useRoleAccess = (name: MenuNames) => {
  const menus = useAppSelector((state) => state.menus);

  const selectedMenu = useMemo(() => {
    return menus.find(({ name: _n }) => _n?.toLocaleLowerCase() === name);
  }, [menus, name]);

  return {
    roleAccess: selectedMenu?.roleAccess,
    additionalRoleAccess: selectedMenu?.additionalRoleAccess,
  };
};
